import React, { useState } from 'react';
import PropTypes from "prop-types";

import { Row, Col, Button } from "react-bootstrap";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { ThreeDots } from 'react-loading-icons'
import SectionHeader from "components/SectionHeader";
import PageSection from "components/PageSection";
import axios from 'axios';
import Floral from "../../../assets/floral.svg"

import "./Contact.scss";

const Contact = ({ className, frontmatter }) => {
  const [submitted, setSubmitted] = useState(false);
  const [attending, setAttending] = useState(false);
  if (!frontmatter) {
    return null;
  }

  const { anchor, header, subheader, form } = frontmatter;

  const RadioField = ({ field, label }) => {
    return (
    <div className="input-radio">
      <label htmlFor={field.value} >
        <input {...field} type="radio" id={field.value} />
        <span>{label}</span>
      </label>
      </div>
    );
  };

  RadioField.propTypes = {
    field: PropTypes.any.isRequired,
    label: PropTypes.string.isRequired
  };

  const FieldWithLabel = ({ field, label, type }) => {
      return (
      <Row className="justify-content-center">
        <Col lg={6}>
          <input {...field} placeholder={label} type={type} id={field.name} className="input-field" />
        </Col>
        </Row>
      );
    };

  FieldWithLabel.propTypes = {
    field: PropTypes.any.isRequired,
    label: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired
  };

  return (
    <PageSection className={className} id={anchor}>
      <Row className="justify-content-center">
          <Floral className="header-banner" />
          <SectionHeader header={header} subheader={subheader} />
      </Row>
      <Row>
        <Col lg={12} className="text-center">
          { !submitted && <Formik
                 initialValues={{ name: '', email: '', guests: '', attending: '' }}
                 validate={values => {
                   const errors = {};
                   if (!values.name) {
                    errors.name = form.name.required;
                    }
                   if (!values.email) {
                     errors.email = form.email.required;
                   } else if (
                     !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                   ) {
                     errors.email = form.email.invalid;
                   }
                   if (!values.attending) {
                    errors.attending = form.attending.required;
                   }
                   if (values.attending === 'yes' && !values.guests) {
                    errors.guests = form.guests.required;
                   }
                   if (values.attending === 'yes' && values.guests < 1 || values.guests > 10) {
                    errors.guests = form.guests.invalid;
                   }
                   return errors;
                 }}
                 onSubmit={(values, { setSubmitting }) => {
                   const formData = new FormData();
                   Object.keys(values).forEach(key => formData.append(key, values[key]));
                   axios.post('https://script.google.com/macros/s/AKfycbxDJuk47c9at8x6u4q4JIw1nnnltfP57_jy66o3iS52yWpiXyk/exec', formData, { headers: {"Content-Type": "application/x-www-form-urlencoded"} })
                     .then(function (response) {
                       setSubmitted(true);
                       setAttending(values.attending === "yes")
                       setSubmitting(false);
                     })
                     .catch(function (error) {
                       setSubmitting(false);
                     });
                 }}
               >
                 {({ isSubmitting, values }) => (
                   <Form>
                     <Field name="name" type="text" component={FieldWithLabel} label={form.name.label}/>
                     <ErrorMessage name="name" component="div" />
                     <Field name="email" type="email" component={FieldWithLabel} label={form.email.label}/>
                     <ErrorMessage name="email" component="div" />
                     <Row className="justify-content-center">
                     <Col lg={6}>
                     <div className="input-radio-wrapper">
                    <Field name="attending" type="radio" value="yes" component={RadioField} label={form.attending.labels[0] + " " + String.fromCodePoint(0x1F973)}/>
                    <Field name="attending" type="radio" value="no" component={RadioField} label={form.attending.labels[1] + " " + String.fromCodePoint(0x1F440)}/>
                    </div>
                    </Col>
                    </Row>
                    <ErrorMessage name="attending" component="div" />
                    { values.attending === "yes" && (
                    <>
                      <Field name="guests" type="number" component={FieldWithLabel} label={form.guests.label}/>
                      <ErrorMessage name="guests" component="div" />
                      </>
                    )}
                    <Row className="justify-content-center">
                    <Col lg={6}>
                    <Field as="textarea" name="message" className="input-field" placeholder={form.message.label} />
                    <ErrorMessage name="message" component="div" />
                    </Col>
                    </Row>
                    <Row className="justify-content-center">
                            <Col lg={8} className="text-center">
                     <Button size="md" variant="primary" disabled={isSubmitting} type="submit">
                       { !isSubmitting ? form.submit : <ThreeDots fill="#000000" />}
                     </Button>
                     </Col>
                                                                                     </Row>
                   </Form>
                 )}
               </Formik>}
            {submitted && (attending ? <h3 className="section-subheading text-muted">{ form.success.coming }</h3> : <h3 className="section-subheading text-muted">{ form.success.notComing }</h3>)}
        </Col>
      </Row>
    </PageSection>
  );
};

Contact.propTypes = {
  className: PropTypes.string,
  frontmatter: PropTypes.object,
};

Contact.defaultProps = {
  className: null,
  frontmatter: null,
};

export default Contact;
