import React from "react";
import PropTypes from "prop-types";

import { Row, Col } from "react-bootstrap";
import CovidItem from "components/CovidItem";
import SectionHeader from "components/SectionHeader";
import PageSection from "components/PageSection";
import "./Covid.scss";
import CovidCert from "../../../assets/covid-cert.svg"
import CovidMask from "../../../assets/covid-mask.svg"
import CovidHealthy from "../../../assets/covid-healthy.svg"

const Covid = ({ className, frontmatter }) => {
  if (!frontmatter) {
    return null;
  }

  const {
    anchor,
    header: rootHeader,
    subheader: rootSubHeader,
    intro,
    content: rootContent,
    covidItem,
  } = frontmatter;

  const renderIcon = (iconId) => {
    switch(iconId) {
      case 'covid-cert':
        return <CovidCert className="covid-icon" />;
      case 'covid-mask':
        return <CovidMask className="covid-icon" />;
      case 'covid-healthy':
        return <CovidHealthy className="covid-icon" />;
      default:
        return <CovidMask className="covid-icon" />;
    }
  };

  return (
    <PageSection className={className} id={anchor}>
      <Row className="justify-content-center">
        <SectionHeader header={rootHeader} subheader={rootSubHeader} />
        <Col>
        <Row className="justify-content-center">
          <Col md={8}>
            <p className="covid-intro">{intro}</p>
          </Col>
        </Row>
        <Row className="justify-content-center">
        {covidItem.map(({ header, ...tmProps }) => (
            <Col key={header} lg={3} className="text-center">
              { renderIcon(tmProps.icon) }
              <CovidItem header={header} {...tmProps} />
            </Col>
          ))}
          </Row>
        </Col>
      </Row>
    </PageSection>
  );
};

Covid.propTypes = {
  className: PropTypes.string,
  frontmatter: PropTypes.object,
};

Covid.defaultProps = {
  className: null,
  frontmatter: null,
};

export default Covid;
