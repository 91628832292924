import React from "react";
import PropTypes from "prop-types";

import { Row, Col } from "react-bootstrap";

import PageSection from "components/PageSection";
import Flower from "../../../assets/flower.svg"

import "./Services.scss";

const Services = ({ className, frontmatter }) => {
  if (!frontmatter) {
    return null;
  }

  const { anchor, header: rootHeader, subheader: rootSubHeader, paragraphs } = frontmatter;

  return (
    <PageSection className={className} id={anchor}>
      <Row className="justify-content-center">
        <Flower className="heading-icon" />
      </Row>
      <Row className="justify-content-center text-center">
        <Col lg={6}>
          {paragraphs.map(({key, text}) => (
            <p key={key} className="large">
              {text}
            </p>
          ))}
        </Col>
      </Row>
    </PageSection>
  );
};

Services.propTypes = {
  className: PropTypes.string,
  frontmatter: PropTypes.object,
};

Services.defaultProps = {
  className: null,
  frontmatter: null,
};

export default Services;
