import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

import { Button } from "react-bootstrap";

import "./TimelineItem.scss";

const TimelineItem = ({
  invert,
  imageAlt,
  header,
  subheader,
  googleMapsUrl,
  content,
  whereToParkText,
  whereToParkUrl
}) => {
  const headerPart = header ? <h4>{header}</h4> : null;
  const subheaderPart = subheader ? <h4 className="subheading">{subheader}</h4> : null;

  const liClassName = clsx("timeline-item", { "timeline-inverted": invert });

  return (
    <li className={liClassName}>
      <div className="timeline-image" />
      <div className="timeline-panel">
        <div className="timeline-heading">
          {headerPart}
          {subheaderPart}
        </div>
        <div className="timeline-body">
          <p className="text-muted">{content}</p>
          { googleMapsUrl && <Button size="sm" variant="primary" href={googleMapsUrl} target="_blank">
            Google Maps
          </Button> }
          { whereToParkText && whereToParkUrl && <Button size="sm" variant="link" href={whereToParkUrl} target="_blank">
            { whereToParkText }
          </Button> }
        </div>
      </div>
    </li>
  );
};

TimelineItem.propTypes = {
  invert: PropTypes.bool,
  imageAlt: PropTypes.string,
  header: PropTypes.string,
  subheader: PropTypes.string,
  googleMapsUrl: PropTypes.string,
  content: PropTypes.string,
  whereToParkText: PropTypes.string,
  whereToParkUrl: PropTypes.string
};

TimelineItem.defaultProps = {
  invert: false,
  imageAlt: "",
  header: "",
  subheader: "",
  googleMapsUrl: "",
  content: "",
  whereToParkText: "",
  whereToParkUrl: ""
};

export default TimelineItem;
