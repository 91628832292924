import React from "react";
import PropTypes from "prop-types";

import "./Custom.scss";

const Custom = ({
  header,
  subheader,
  funFactTxt,
  funFactPrefix,
  content,
}) => {
  return (
    <div className="custom-item">
      <h4>{header}</h4>
      <p className="text-muted">{subheader}</p>
      <p className="large text-left">{content}</p>
      { funFactTxt && <p className="text-left font-italic"><span className="text-muted">{funFactPrefix}</span> {funFactTxt}</p> }
    </div>
  );
};

Custom.propTypes = {
  header: PropTypes.string,
  subheader: PropTypes.string,
  funFactTxt: PropTypes.string,
  funFactPrefix: PropTypes.string,
  content: PropTypes.string,
};

Custom.defaultProps = {
  header: "",
  subheader: "",
  funFactTxt: "",
  funFactPrefix: "",
  content: "",
};

export default Custom;
