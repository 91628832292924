import React from "react";
import PropTypes from "prop-types";

import { Row, Col } from "react-bootstrap";
import Custom from "components/Custom";
import SectionHeader from "components/SectionHeader";
import PageSection from "components/PageSection";
import "./Customs.scss";
import Barjaktar from "../../../assets/barjaktar-partial.svg"
import Kolona from "../../../assets/kolona-full.svg"

const Customs = ({ className, frontmatter }) => {
  if (!frontmatter) {
    return null;
  }

  const {
    anchor,
    header: rootHeader,
    subheader: rootSubHeader,
    intro,
    content: rootContent,
    customItem,
  } = frontmatter;

  return (
    <PageSection className={className} id={anchor}>
      <Row className="justify-content-center">
        <SectionHeader header={rootHeader} subheader={rootSubHeader} />
        <Col>
        <Row className="justify-content-center">
          <Col md={8} className="text-center">
            <p className="customs-intro">{intro}</p>
          </Col>
        </Row>
        {customItem.map(({ header, ...tmProps }) => (
                <Row key={header} className="justify-content-center">
                  <Col md={2} className="text-center">
                    { tmProps.icon === 'barjaktar' ? <Barjaktar className="customs-icon" /> : <Kolona className="rotated" /> }
                  </Col>
                  <Col md={6}>
                    <Custom header={header} {...tmProps} />
                  </Col>
                </Row>
                ))}
        </Col>
      </Row>
    </PageSection>
  );
};

Customs.propTypes = {
  className: PropTypes.string,
  frontmatter: PropTypes.object,
};

Customs.defaultProps = {
  className: null,
  frontmatter: null,
};

export default Customs;
