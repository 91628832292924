import React from "react";
import PropTypes from "prop-types";

import { Button } from "react-bootstrap";
import Image from "components/Image";

import "./TeamMember.scss";

const TeamMember = ({
  imageFileName,
  imageAlt,
  header,
  subheader,
  googleMapsUrl,
  moreTxt,
  moreUrl,
  content,
}) => {
  return (
    <div className="team-member">
      <Image
        className="mx-auto circle rounded-circle"
        fileName={imageFileName}
        alt={imageAlt || header || subheader}
      />
      <h4>{header}</h4>
      <p className="text-muted">{subheader}</p>
      <p className="large text-justify">{content} <Button size="sm" variant="link" href={moreUrl} target="_blank">{moreTxt}</Button></p>
      <div>
        <Button size="sm" variant="primary" href={googleMapsUrl} target="_blank">
          Google Maps
        </Button>
      </div>
    </div>
  );
};

TeamMember.propTypes = {
  imageFileName: PropTypes.string.isRequired,
  imageAlt: PropTypes.string,
  header: PropTypes.string,
  subheader: PropTypes.string,
  googleMapsUrl: PropTypes.string,
  moreTxt: PropTypes.string,
  moreUrl: PropTypes.string,
  content: PropTypes.string,
};

TeamMember.defaultProps = {
  imageAlt: null,
  header: "",
  subheader: "",
  googleMapsUrl: "",
  moreTxt: "",
  moreUrl: "",
  content: "",
};

export default TeamMember;
