import React from "react";
import PropTypes from "prop-types";

import { Container, Row, Col } from "react-bootstrap";
import ICalendarLink from "react-icalendar-link";

import "./Footer.scss";

const Footer = ({ frontmatter }) => {
  if (!frontmatter) {
    return null;
  }

  const {
    weddingIsIn,
    addToCalendar,
    days: { singular, plural, specialPlural },
    event: { title, description },
    weddingIsToday,
    weddingIsOver,
  } = frontmatter;

  const event = {
    title,
    description,
    startTime: "2021-09-10T17:00:00+02:00",
    endTime: "2021-09-11T04:00:00+02:00",
    location: "Crkva Uznesenja Blažene Djevice Marije, Rijeka"
  }

  const daysLeft = () => {
    const now = new Date();
    const targetDate = new Date(2021, 8, 10);
    return Math.ceil((targetDate - now) / 1000 / 60 / 60 / 24);
  };

  const daysLeftText = () => {
    const days = daysLeft();
    const postfix = (days !== 11 && days % 10 === 1) ? `${specialPlural}, ` : `${plural}, `;
    if (days > 0) {
      return `${weddingIsIn} ${days} ${postfix}`;
    }
    if (days === 0) {
      return weddingIsToday;
    }
    return weddingIsOver;
  };

  return (
    <footer className="footer bg-light py-3">
      <Container>
        <Row className="text-center">
          <Col lg={4} className="text-lg-left">
            <a href="mailto:kapetanovic.lea@gmail.com">kapetanovic.lea@gmail.com</a>
            <br />
            <a href="mailto:marin@krmpoti.ch">marin@krmpoti.ch</a>
          </Col>
          <Col lg={8} className="text-lg-right">
            {daysLeftText()}
            {daysLeft() > 0 &&
              <ICalendarLink event={event}>
                {addToCalendar}
              </ICalendarLink>
            }
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

Footer.propTypes = {
  frontmatter: PropTypes.object,
};

Footer.defaultProps = {
  frontmatter: null,
};

export default Footer;
