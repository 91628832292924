import React from "react";
import PropTypes from "prop-types";

import { Button } from "react-bootstrap";

import "./CovidItem.scss";

const CovidItem = ({
  header,
  content,
  links,
  highlighted,
}) => {
  return (
    <div className="covid-item">
      <h4>{header}</h4>
      {links && links.map(({text, href}) => (
        <Button key={href} size="sm" variant="link" href={href} target="_blank">{text}</Button>
      ))}
      <p className="large text-justify">{content} { highlighted && <mark>{highlighted}</mark> }</p>
    </div>
  );
};

CovidItem.propTypes = {
  header: PropTypes.string,
  links: PropTypes.array,
  content: PropTypes.string,
  highlighted: PropTypes.string,
};

CovidItem.defaultProps = {
  header: "",
  links: [],
  content: "",
  highlighted: "",
};

export default CovidItem;
